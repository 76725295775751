<template>
  <div>
    <v-alert v-if="isChromeAndroidRequired" type="error" text class="mb-3">
      <p>
        Attenzione, il tuo browser non è compatibile. Ti suggeriamo di scaricare
        <a
          href="https://play.google.com/store/apps/details?id=com.android.chrome"
          class="font-weight-medium"
        >
          Google Chrome
        </a>
        oppure
        <a
          href="https://play.google.com/store/apps/details?id=org.mozilla.firefox"
          class="font-weight-medium"
        >
          Mozilla Firefox
        </a>
        . Se hai già Chrome, clicca sul bottone.
      </p>
      <v-btn color="primary" outlined block :href="linkToBrowser">
        <v-icon left>mdi-google-chrome</v-icon>
        Apri in Chrome</v-btn
      >
    </v-alert>
    <v-alert v-if="isSafariRequired" type="error" text class="mb-3">
      <p>
        Attenzione, il tuo browser non è compatibile con il servizio. Puoi
        utilizzare Safari, clicca sul bottone.
      </p>
      <v-btn color="primary" outlined block :href="linkToBrowser">
        <v-icon left>mdi-google-chrome</v-icon>
        Apri in Safari
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
import {
  browserName,
  isAndroid,
  isChrome,
  isFirefox,
  isIOS,
  isSafari,
} from 'mobile-device-detect'

import { isWebRTCSupported } from 'detectrtc'

export default {
  name: 'SuggestedBrowserBanner',
  computed: {
    browser() {
      return browserName
    },
    isWebRTCNotSupported() {
      return !isWebRTCSupported
    },
    isChromeAndroidRequired() {
      return !isWebRTCSupported && isAndroid && !(isChrome || isFirefox)
    },
    isSafariRequired() {
      return !isWebRTCSupported && isIOS && !isSafari
    },
    linkToBrowser() {
      if (isAndroid)
        return (
          'googlechrome://navigate?url=' +
          window.location.href.replace(window.location.protocol, '')
        )
      else if (isIOS)
        return (
          'safari-https://' +
          window.location.href.replace(window.location.protocol, '')
        )
      else return null
    },
  },
  methods: {
    openWithChrome() {
      const dest =
        'googlechrome://navigate?url=' +
        window.location.href.replace(window.location.protocol, '')

      document.location = dest
    },
    openWithSafari() {
      const dest =
        'safari-https://' +
        window.location.href.replace(window.location.protocol, '')

      document.location = dest
    },
  },
}
</script>

<style scoped></style>
